.introduction {
    background-color: white;
    margin-top: 3.25rem;
}

.introduction h1, .introduction p{
    text-align: center;
}
.grey-section {
    background-color: #d1cdcd;
    height: 50vh;
    display: block;
}

.profile-name-jobtitle-section {
    font-weight: 300;
    text-transform: uppercase;
    margin: 60px auto 0;
}

.scroll-arrow {
    opacity: 0;
    animation: appear-and-move 4s forwards;
  }
  
  @keyframes appear-and-move {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateY(-20px);
    }
  }


@media only screen and (max-width: 600px) {
    .profile-container .profile-avatar {
        width: 60vw;
        margin: -30% auto 0;
        height: auto;
        border-style: solid;
        border-width: 8px;
        border-color: white;
    }
    .grey-section {
        background-color: #d1cdcd;
        height: 25vh;
        display: block;
    }
    
    .white-section {
        height: 25vh;
        text-align: center;
    }
}

@media only screen and (min-width: 600px) {
    .profile-container .profile-avatar {
        width: 60vw;
        margin: -30% auto 0;
        height: auto;
        border-style: solid;
        border-width: 8px;
        border-color: white;
    }
}


@media only screen and (min-width: 768px) {
    .profile-container .profile-avatar {
        width: 60vw;
        margin: -30% auto 0;
        height: auto;
        border-style: solid;
        border-width: 8px;
        border-color: white;
    }
}

@media only screen and (min-width: 992px) {
    .profile-container .profile-avatar {
        width: 350px;
        margin: -10% auto 0;
        height: auto;
        border-style: solid;
        border-width: 8px;
        border-color: white;
    }   
}

