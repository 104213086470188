*{
    /* Setting global margins and padding to 0*/ 
    margin: 0;
    padding: 0;
    /* Setting global fonts*/
    font-family: 'Montserrat', sans-serif;
}

html, body {
    overflow-x:hidden 
}

.loader-container {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    position: relative;
    color: #000;
    text-decoration: none;
}
a:hover {
    color: #000;
}

a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;
}

a:hover::before {
transform: scaleX(1);
}

.photography {
    background-color: blue
}
.projects {
    background-color: black
}
.contact-me {
    background-color: white;
}